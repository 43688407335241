<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
        <div>
          <q-card>
            <q-item-section v-if="this.render_table">
              <q-item>
                <label class="text-h5" > {{this.stock_item.description}} : </label>
                <q-input
                    v-model.number="this.count"
                    type="number"
                    filled
                    style="max-width: 200px"
                ></q-input>
              </q-item>
            </q-item-section>
            <q-card-actions align="right" class="bg-white text-teal">
              <q-btn label="Done" @click="this.submitCount()" type="submit" color="primary"></q-btn>
            </q-card-actions>
          </q-card>


        </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRoute } from 'vue-router'
import {Notify, useQuasar} from "quasar";

export default {
  name: 'CountStockPage',
  setup(){

  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    },
    level_ones : function(){
      return this.$store.getters.level_ones;
    },
    one_description: function(){
      return this.$store.getters.one_description;
    },
    current_level_one: function(){
      let one = this.$store.getters.current_level_one;
      return one;
    },
    level_twos : function(){
      return this.$store.getters.level_twos;
    },
    two_description: function(){
      return this.$store.getters.two_description;
    },
    current_level_two: function(){
      let two = this.$store.getters.current_level_two;
      return two;
    },
    level_threes : function(){
      return this.$store.getters.level_threes;
    },
    three_description: function(){
      return this.$store.getters.three_description;
    },
    current_level_three: function(){
      let three = this.$store.getters.current_level_three;
      return three;
    },
    level_fours : function(){
      return this.$store.getters.level_fours;
    },
    four_description: function(){
      return this.$store.getters.four_description;
    },
    current_level_four: function(){
      let four = this.$store.getters.current_level_four;
      return four;
    },
    level_fives : function(){
      return this.$store.getters.level_fives;
    },
    current_level_five: function(){
      let five = this.$store.getters.current_level_five;
      return five;
    },
    five_description: function(){
      return this.$store.getters.five_description;
    },
    token: function () {
      return this.$store.getters.token;
    },
    docket_description: function(){
      return this.$store.getters.docket_description;
    },
  },
  data(){
    return {
      count: null,
      render_table : false,
      parameter_id: null,
      stock_item : null,
      current_user : null,
      users : null

    }
  },
  mounted(){
    this.setupParameters();
    this.getStock();
  },
  methods : {
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
    },
    async getStock(){
      const $q = useQuasar();
        axios.get(this.$store.getters.url+'/api/api-stock/'+this.parameter_id,{
              headers :{
                'Authorization' : 'Bearer '+this.token,
              }
        })
            .then((res) => {
              // eslint-disable-next-line no-unreachable
              $q.notify({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Stock Retrieved !! '
              })
              this.stock_item = res.data;
              console.log(this.stock_item);
              this.render_table = true;
            })
            .catch((error) => {
              console.log(error)
              $q.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error retrieving stock: '+error
              })
            }).finally(() => {
          //Perform action in always
        });
    },
    submitCount(){
      let data = new FormData();
      data.append("stock_id", this.stock_item.id);
      data.append("quantity", this.count);
      data.append('type','count');
      if(this.count < 1){
        Notify.create({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'Count FAILED: '
        })
        return;
      }
      let config = {
        header: {
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(this.$store.getters.url+'/api/api-stock-movement', data, config)
          .then((res) => {
            console.log(res);
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Count saved!'
            })
            this.$router.push({ name: "list-stock"})
          })
          .catch((error) => {
            console.log(error);
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Count FAILED: ' + error.response.data.message
            })
          }).finally(() => {
        //Perform action in always
      });
    }
  }
}
</script>
