<template>
      <q-list bordered>
          <q-item>
            <q-item-section class="text-h5">Case: {{this.returned_cases.id}}</q-item-section>
          </q-item>
          <q-item>
            <div>
              <div v-bind="getRootProps()">
                <input v-bind="getInputProps()" />
              </div>
              <label class="q-mx-md q-ml-auto">Take a photo:</label>
              <q-btn icon="camera_alt" round @click="open"></q-btn>
            </div>
          </q-item>
      </q-list>
      <q-list>
        <q-item>
          <q-item-section v-for="(media,index) in this.returned_cases.media_folders" :key="index">
            <div v-for="(media_container,counter) in media.media_containers" class="q-mb-md q-mt-md" :key="counter">
                  <q-img v-if="hasImageExtension(media_container.media[0].original_url)" :src="media_container.media[0].original_url" />
                  <q-video v-if="hasVideoExtension(media_container.media[0].original_url)" :src="media_container.media[0].original_url" />
                  <a :href="media_container.media[0].original_url" v-if="!hasImageExtension(media_container.media[0].original_url) && !hasVideoExtension(media_container.media[0].original_url)" name="insert_drive_file" >
                      {{media_container.original_name}}
                  </a>
            </div>

          </q-item-section>
        </q-item>
      </q-list>

</template>


<script>

import axios from "axios";
import {Notify} from "quasar";
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex';
import {  ref } from 'vue';
export default {
  name: 'CaseAttachments',
  props: [
      'returned_cases'
  ],
  emits:[
      'camera_open',
      'camera_close'
  ],
  computed: {
    case_id(){
      return this.returned_cases.id
    },
    user_id(){
        return this.$store.getters.user_id;
    }
  },
  setup(props) {
    function onDrop(acceptFiles) {
      console.log(acceptFiles);
      saveFiles(acceptFiles); // saveFiles as callback
    }
    const store = useStore();
    function saveFiles(files) {
      files.forEach((panicPhoto) => {
        console.log(panicPhoto);
        let file_data = new FormData();
        file_data.append('object_class','App\\Models\\Docket');
        file_data.append("object_id", props.returned_cases.id);
        file_data.append("folder_id", props.returned_cases.media_folders[0].id);
        file_data.append('images[]', panicPhoto, panicPhoto.name);
        axios.post(store.state.url + '/api/api-media', file_data)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'File Uploaded'
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'File FAILED: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
      });
    }

    let media = ref([]);


    const {getRootProps, getInputProps, ...rest} = useDropzone({onDrop});
    return {
      media,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  methods:{
    hasImageExtension(path){
      return path.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    hasVideoExtension(path){
      return path.match(/\.(mp4|webm|ogg)$/) != null;
    },
  }
}
</script>

<style>
.camera-container {
  z-index: 10; /* Higher than the footer */
}
video {
  /* This will make the video fill the element's dimensions, possibly cropping it */
  object-fit: cover;

  /* Use the entire viewport width and height */
  width: 100vw;
  height: 100vh;

  /* Optional: Center the video in its container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
