import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';
import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import { defineCustomElements } from '@ionic/pwa-elements/loader'; // <== NEW
import Vue3Signature from "vue3-signature"
import './registerServiceWorker'
import {initializeApp} from "firebase/app";
import {getMessaging} from "firebase/messaging";
import store from '@/stores/appStore';

const firebaseConfig = {
    apiKey: "AIzaSyACAM_mb5hzOZVt3BfGSaHVGTCR1XV8Qik",
    authDomain: "blackwidow-cms-demo.firebaseapp.com",
    projectId: "blackwidow-cms-demo",
    storageBucket: "blackwidow-cms-demo.appspot.com",
    messagingSenderId: "365408920007",
    appId: "1:365408920007:web:7d91725e32354a5d8c39e2"
};

const app = initializeApp(firebaseConfig);


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            console.log(registration);
            const messaging = getMessaging(app);
            console.log(messaging);
            // Other Firebase Messaging setup...
        })
        .catch((err) => {
            console.error("Service Worker registration failed: ", err);
        });
}

store.dispatch('initializeStore');

createApp(App)
    .use(Quasar, {
        plugins: {
            Notify
        },
        config: {
            notify: { /* look at QuasarConfOptions from the API card */ }
        }
    })
    .use(store)
    .use(Vue3Signature)
    .use(quasarUserOptions)
    .use(router)
    .mount('#app')

defineCustomElements(window);  // <== NEW
