<template>
  <div class="bg-white">
    <div v-bind="getRootProps()">
      <input v-bind="getInputProps()" />
      <p v-if="isDragActive">Drop the files here ...</p>
      <p v-else><svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M4 18V8a1 1 0 0 1 1-1h1.5l1.707-1.707A1 1 0 0 1 8.914 5h6.172a1 1 0 0 1 .707.293L17.5 7H19a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Z"/>
        <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
      </svg>
      </p>
    </div>
    <button @click="open">Upload/Capture</button>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { ref } from 'vue';

export default {
  name: "DropzoneComponent",
  setup(props, { emit }) {
    const files = ref([]);

    function onDrop(acceptFiles) {
      files.value = acceptFiles;

      if (files.value.length > 0) {
        let file = files.value[0];
        let extension = file.name.split('.').pop();
        let reader = new FileReader();

        reader.onload = function (event) {
          let blob = new Blob([event.target.result], { type: file.type });
          emit('update:files', blob);
        };

        reader.onerror = function (event) {
          console.error('Error reading file:', event.target.error);
          emit('fileError', 'Failed to read the file');
        };

        emit('update:extension', extension);
        reader.readAsArrayBuffer(file);
      } else {
        console.log('No file selected');
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      files,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>