<template>
  <div class="radial-menu">
    <div class="center-button" @click="toggleMenu">
      <slot name="center"></slot>
    </div>
    <div
        v-for="(item, index) in items"
        :key="index"
        class="menu-item"
        :style="getItemStyle(index)"
    >
      <slot :name="'item-' + index">
        <div @click="$emit('clickedEvent', item)">
          {{ item.description }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    radius: {
      type: Number,
      default: 100, // Adjust the radius to be smaller
    },
  },
  emits: ['clickedEvent'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    getItemStyle(index) {
      const angle = (360 / this.items.length) * index;
      const radians = (angle * Math.PI) / 180;
      const x = this.isOpen ? this.radius * Math.cos(radians) : 0;
      const y = this.isOpen ? this.radius * Math.sin(radians) : 0;
      return {
        transform: `translate(${x}px, ${y}px)`,
      };
    },
  },
};
</script>

<style scoped>
.radial-menu {
  position: relative;
  width: 150px; /* Smaller menu size */
  height: 150px; /* Smaller menu size */
  margin: auto;
}

.center-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.menu-item {
  position: absolute;
  top: 33%; /* Center the items */
  left: 33%; /* Center the items */
  width: 50px; /* Smaller menu item size */
  height: 50px; /* Smaller menu item size */
  transform: translate(-50%, -50%); /* Adjust for proper centering */
  transition: transform 0.3s;
  border-radius: 50%;
  background-color: rgb(135, 235, 140);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, red, indianred);
}

/* Add a shine effect */
.menu-item::before {
  background: radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
  opacity: 0.8;
}

.menu-item::after {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
  opacity: 0.9;
  filter: blur(1px);
}
</style>
