<template>
      <q-list bordered>
        <q-item>
          <q-item-section class="text-h5">Case Notes</q-item-section>
          <q-item-section avatar>
            <q-icon color="primary" name="notepad"></q-icon>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-btn label="Add Note" @click="addNote()" type="submit" color="primary"></q-btn>
          </q-item-section>
        </q-item>
          <q-item v-for="(note_field,index) in this.notes" :key="index">
            <q-item-section bordered class="border">
              <p>
                <strong>Author: {{ note_field.user.full_name }} </strong>
              </p>
              <p>
                {{note_field.content}}
              </p>
              <p>Date: {{note_field.created_at}}</p>
            </q-item-section>
          </q-item>
      </q-list>
</template>


<script>

import {useRouter} from "vue-router";
import axios from "axios";
import {Notify} from "quasar";

export default {
  name: 'CaseNotes',
  props: [
      'returned_cases',
      'reload'
  ],
  emits: ['addNote'],
  data(){
    return {
      notes : []
    }
  },
  setup(){

  },
  methods:{
    addNote(){
      this.$emit('addNote')
    },
    getCaseNotes(){
      const router = useRouter()
      let token = this.$store.getters.token;
      if( token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }else{
        router.push({ name: "login"})
      }
      axios.get(this.$store.getters.url+'/api/api-notes?docket_id='+this.$route.params.id)
          .then((res) => {
             this.notes = res.data
             Notify.create({
               progress: true,
               timeout: 1000,
               position: 'center',
               color: 'green-4',
               textColor: 'white',
               icon: 'cloud_done',
               message: 'Notes loaded'
             });
          })
          .catch(() => {
          }).finally(() => {
      });
    },
  },
  watch: {
    reload(){
      this.getCaseNotes();
    }
  },
  mounted(){
      this.getCaseNotes();
  }
}
</script>
