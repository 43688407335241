<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
        <div :key="render_table">
          <q-card>
            <div v-if="this.organizedStockItems" class="text-h3">There are  {{this.available_to_count}} items available to count</div>
            <div v-for="(stock_item,index) in stock_items" :key="index">
              <q-list bordered class="rounded-borders">
                  <q-item :to="'/count-stock/'+stock_item.id" :class="{'bg-red-3': !isCreatedWithinLast24Hours(stock_item),'bg-green-3': isCreatedWithinLast24Hours(stock_item)}">
                    <q-item-section class="text-h5" :id="index">{{stock_item.id}} - {{ stock_item.description}}</q-item-section>
                    <q-item-section avatar>
                      <q-icon color="primary" name="settings"></q-icon>
                    </q-item-section>
                  </q-item>
                  <q-item :to="'/count-stock/'+stock_item.id" :class="{'bg-red-2': !isCreatedWithinLast24Hours(stock_item),'bg-green-2': isCreatedWithinLast24Hours(stock_item)}">
                    <q-item-section v-if="stock_item.stock_movements && stock_item.stock_movements.length > 0">
                      <q-item-section v-if="isCreatedWithinLast24Hours(stock_item)">
                        Last count date {{ stock_item.stock_movements[0].created_at }}
                      </q-item-section>
                      <div v-if="!isCreatedWithinLast24Hours(stock_item)">
                        Last count date {{ stock_item.stock_movements[0].created_at }}
                      </div>
                    </q-item-section>
                    <q-item-section v-else>
                      <q-item-section>
                        No count has been done on this item
                      </q-item-section>
                    </q-item-section>
                  </q-item>
              </q-list>
          </div>
          </q-card>
        </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import {useQuasar} from "quasar";

export default {
  name: 'ListStockPage',
  setup(){
    let branch = JSON.parse( localStorage.getItem('branch') );
    return {
      branch
    }
  },
  data(){
    return {
      stock_items : [],
      stock_items_movements : [],
      render_table : null,
      current_user : null,
      users : null

    }
  },
  mounted(){
    this.syncData();
  },
  computed:{
      url: function(){
        return this.$store.getters.url;
      },
      user_id : function(){
        return JSON.parse( this.$store.getters.user_id );
      },
      level_ones : function(){
        return this.$store.getters.level_ones;
      },
      one_description: function(){
        return this.$store.getters.one_description;
      },
      current_level_one: function(){
        let one = this.$store.getters.current_level_one;
        return one;
      },
      level_twos : function(){
        return this.$store.getters.level_twos;
      },
      two_description: function(){
        return this.$store.getters.two_description;
      },
      current_level_two: function(){
        let two = this.$store.getters.current_level_two;
        return two;
      },
      level_threes : function(){
        return this.$store.getters.level_threes;
      },
      three_description: function(){
        return this.$store.getters.three_description;
      },
      current_level_three: function(){
        let three = this.$store.getters.current_level_three;
        return three;
      },
      level_fours : function(){
        return this.$store.getters.level_fours;
      },
      four_description: function(){
        return this.$store.getters.four_description;
      },
      current_level_four: function(){
        let four = this.$store.getters.current_level_four;
        return four;
      },
      level_fives : function(){
        return this.$store.getters.level_fives;
      },
      current_level_five: function(){
        let five = this.$store.getters.current_level_five;
        return five;
      },
      five_description: function(){
        return this.$store.getters.five_description;
      },
      token: function () {
        return this.$store.getters.token;
      },
      docket_description: function(){
        return this.$store.getters.docket_description;
      },
      organizedStockItems() {
        return this.stock_items;
      },
      available_to_count() {
        let count = 0;
        // Create a map to associate stock item IDs with their respective movements
        const stockItems = this.organizedStockItems;
        stockItems.forEach(stock_item =>{
            if(!this.isCreatedWithinLast24Hours(stock_item)){
              count++;
            }
        });

        return count;
      },
  },
  methods : {
    isCreatedWithinLast24Hours(stockItem) {
      // eslint-disable-next-line no-prototype-builtins
      if (stockItem.stock_movements && stockItem.stock_movements.length > 0) {
        const createdAtTimestamp = new Date(stockItem.stock_movements[0].created_at);
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
        console.log(createdAtTimestamp > twentyFourHoursAgo);
        return createdAtTimestamp > twentyFourHoursAgo;
      }else{
         return false
      }
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/cases/stock')
          .then((res) => {
              this.stock_items = res.data.data;
          })
          .catch(() => {
          }).finally(() => {
          });
    },

    async syncData() {
      const $q = useQuasar();
      axios.get(this.$store.getters.url + '/api/api-stock', {
        headers: {
          Authorization: 'Bearer ' + this.token
        },
        params: {
          level_five_id: this.current_level_five.id
        }
      })
          .then((res) => {
            // eslint-disable-next-line no-unreachable
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Stock Items Retrieved !! '
            })
            this.stock_items = res.data;
            this.render_table = true;
          })
          .catch((error) => {
            console.log(error)
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving Stock Items: ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
