<template>
  <q-page class="flex flex-center">
    <div style="min-height: 100vh;">
      <div class="q-mt-md q-mr-sm q-pa-md" style="max-width: 400px">
        <div v-if="!url">
          <q-item-label class="text-h6">
            You need to link your system first
          </q-item-label>
        </div>
        <div class="q-mt-md q-mr-sm" v-if="this.url">
            <q-item-label class="text-h6">
              URL: {{this.url}}
            </q-item-label>
        </div>
        <q-form
            v-if="this.url"
            method="post" @submit.prevent="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
        >

          <q-item-label>
            Cellphone
          </q-item-label>
          <q-input
              v-model="this.cellphone"
              v-maska data-maska="+## ## ### ####"
              type="text"
              name="cellphone"
              id="cellphone"
              class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

          <q-input
              filled
              type="password"
              name="password"
              v-model="this.password"
              label="Password"
              lazy-rules
              aria-autocomplete="current-password"
              :rules="[ val => val && val.length > 0 || 'Please type something']"
          ></q-input>

          <q-toggle v-model="this.accept" label="I accept the license and terms"></q-toggle>

          <div>
            <q-btn label="Submit" type="submit" color="primary"></q-btn>
            <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm"></q-btn>
          </div>
        </q-form>

      </div>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
import { useQuasar } from 'quasar';
import axios from 'axios'
import { useRouter} from 'vue-router' // <- import useRoute here
import {vMaska} from "maska";

export default {
  name: 'PageLogin',
  directives:{
    maska : vMaska
  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    token: function () {
      return this.$store.getters.token;
    },
  },
  setup () {
    let quasar = useQuasar()
    const router = useRouter();
    return { quasar, router }
  },
  data(){
    return {
      initial_url : null,
      input_url : null,
      scanner_active : false,
      cellphone : null,
      password : null,
      accept : false
    }
  },
  methods: {
    onSubmit (evt) {
      console.log('@submit - do something here', evt)
      console.log(this.cellphone);
      console.log(this.password);
      console.log(this.accept);
      if (!this.accept) {
        this.quasar.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'You need to accept the license and terms first'
        })
      }
      else {
        let submitData = {
          "cellphone" : this.cellphone,
          "password" : this.password
        };
        let url = this.$store.getters.url;
        axios.post(url+'/api/login', submitData)
            .then((res) => {
              let token = res.data.token
              this.quasar.notify({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Logged in successfully !! '+token
              })
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              this.$store.commit('UPDATE_TOKEN',token);
              this.$store.commit('UPDATE_USER_ID',res.data.user_id);
              this.$store.commit('UPDATE_USER_NAME',res.data.user.name);
              this.$store.commit('UPDATE_USER_SURNAME',res.data.user.surname);
              localStorage.setItem( 'app_status', JSON.stringify('logged_in') );
              this.router.push({ name: "home"})
            })
            .catch((error) => {
              this.quasar.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Log in Failed'+error
              })
            }).finally(() => {
          //Perform action in always
        });

      }
    },
    onReset () {
      this.cellphone = null
      this.password = null
      this.accept = false
    },
    resetUrl(){
      this.$store.commit('UPDATE_URL',null);
    },
    updateUrl(){
      this.$store.commit('UPDATE_URL',this.input_url);
    },
    onDetect(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onDecode(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onInit(promise) {
      promise.then(() => {
        console.log('QR code reader is ready!');
      }).catch(error => {
        if (error.name === 'NotAllowedError') {
          console.error('Permissions are not granted to use your camera');
        } else if (error.name === 'NotFoundError') {
          console.error('No camera on this device');
        }
        // Handle other initialization errors
      });
    }
  },
  mounted() {
    console.log(this.url);
  }
}
</script>
