<template>
  <q-list bordered class="clickable">
    <q-item>
      <q-item-section class="text-h5">
        {{returned_cases.reference}}
      </q-item-section>
      <q-item-section avatar>
        <q-icon color="primary" name="settings">
          <p> </p>
        </q-icon>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.docket_description }} Description: {{returned_cases.description}}
      </q-item-section>
    </q-item>
    <q-item>
      <div v-if="this.assignedToMe && !this.isAccepted">
        <q-item-section>
          <q-btn @click="acceptCase" label="Accept" color="secondary"></q-btn>
          <q-btn @click="rejectCase" label="Reject" color="red"></q-btn>
        </q-item-section>
      </div>
    </q-item>
    <q-item>
      <q-btn :href="
      'https://www.google.com/maps/dir/?api=1&destination='+this.returned_cases.latitude+','+this.returned_cases.longitude+'&travelmode=driving'" target="_blank" color="primary" label="Get Directions">
      </q-btn>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.docket_description }} Allocated To:
        <span v-if="returned_cases.assigned_to_user">
        {{ returned_cases.assigned_to_user.user.full_name }}
      </span>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>Created By:
        <span v-if="returned_cases.created_by_user">
          {{returned_cases.created_by_user.full_name }}
        </span>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.five_description }} :
        <span v-if="returned_cases.company_level_five">
          {{returned_cases.company_level_five.name}}
        </span>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.docket_description }} Status: <span v-if="returned_cases.status">{{returned_cases.status.name}} </span>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.docket_description }} Stage: <span v-if="returned_cases.stage">{{returned_cases.stage.name}} </span>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        {{ this.docket_description }} category:
        <p v-for="(category,index) in returned_cases.categories" :key="index">
          {{ category.name }}
        </p>
      </q-item-section>
    </q-item>
    <q-item v-if="returned_cases.sub_categories">
      <q-item-section>
        {{ this.docket_description }} Sub category:
        <p v-for="(category,index) in returned_cases.sub_categories" :key="index">
          {{ category.description }}
        </p>
      </q-item-section>
    </q-item>
    <q-item >
      <q-item-section>Log date: {{returned_cases.created_at}}</q-item-section>
    </q-item>
  </q-list>
  <q-list bordered class="clickable">
    <q-item>
      <q-item-section class="text-h5">
        Referrees
      </q-item-section>
      <q-item-section side class="text-h5">
          <q-btn v-if="this.isAccepted && this.assignedToMe" round icon="add" @click="openReferralModal"></q-btn>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section avatar>
        <p v-for="(referree,index) in referees" :key="index">
          {{referree.user.full_name}}
        </p>
      </q-item-section>
    </q-item>
  </q-list>
  <q-list bordered class="clickable">
    <q-item>
      <q-item-section class="text-h5">Followers</q-item-section>
    </q-item>
    <q-item>
      <q-item-section avatar>
        <p v-for="(follower,index) in followers" :key="index">
          {{follower.user.full_name}}
        </p>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="referModalVisible">
        <q-card>
            <q-card-section>
                <q-form>
                    <q-item-label>
                          User to Refer
                    </q-item-label>
                    <q-select
                        v-model="selected_user"
                        :options="this.users"
                        option-label="full_name"
                        option-value="id"
                      ></q-select>
                </q-form>
            </q-card-section>
            <q-card-section v-if="this.assignedToMe && this.isAccepted">
              <q-btn class="bg-blue-5" @click="referCase">Submit</q-btn>
              <q-btn class="bg-red-5" @click="closeReferralModal">Cancel</q-btn>
            </q-card-section>
        </q-card>
  </q-dialog>
  <q-dialog v-model="followerModalVisible">
    <q-card>
      <q-card-section>
        <q-form>
          <q-item-label>
            User to Refer
          </q-item-label>
          <q-select
              v-model="selected_user"
              :options="users"
              option-label="name"
              option-value="id"
          ></q-select>
        </q-form>
      </q-card-section>
      <q-card-section>
        <q-btn class="bg-blue-5" @click="referCase">Submit</q-btn>
        <q-btn class="bg-red-5" @click="closeReferralModal">Cancel</q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>


<script>

import axios from "axios";
import {useRouter} from "vue-router";
import {Notify} from "quasar";

export default {
  name: 'CaseBasic',
  emits: ['reload'],
  props: [
      'returned_cases'
  ],
  computed:{
    referees(){
      if(!this.returned_cases.participants){
        return [];
      }else{
        return this.returned_cases.participants.filter(participant => participant.type === 'referee');
      }
    },
    followers(){
      if(!this.returned_cases.participants) {
        return [];
      }else{
        return this.returned_cases.participants.filter(participant => participant.type === 'follower');
      }
    },
    assignedToMe(){
      return this.checkAssignedToMe();
    },
    isAccepted()  {
       return this.checkAccepted();
    },
    user_id(){
      return this.$store.getters.user_id;
    },
    docket_description(){
      return this.$store.getters.docket_description;
    },
    five_description(){
      return this.$store.getters.five_description;
    }
  },
  data(){
    return {
      referModalVisible : false,
      followerModalVisible : false,
      users : [],
      selected_user: null
    }
  },
  mounted(){
    this.getUsers();
    this.setupPage();
    console.log(this.returned_cases);
  },
  setup(){
  },
  methods:{
    checkAccepted(){
      if(this.returned_cases.assigned_to_user){
        if(this.returned_cases.assigned_to_user.user){
          if(parseInt(this.returned_cases.assigned_to_user.user.id) === parseInt(this.user_id)){
            if(parseInt(this.returned_cases.assigned_to_user.accepted) === 1){
              return true;
            }
          }
        }else{
          return false;
        }
      }else{
        return false;
      }
      return false;
    },
    checkAssignedToMe(){
      if(this.returned_cases.assigned_to_user){
        if(this.returned_cases.assigned_to_user.user){
          if(parseInt(this.returned_cases.assigned_to_user.user.id) === parseInt(this.user_id)){
            return true;
          }
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    setupPage(){
      const router = useRouter()
      let token = this.$store.getters.token;
      if( token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }else{
        router.push({ name: "login"})
      }
      return {
        router
      }
    },
    closeReferralModal(){
      this.referModalVisible = false;
    },
    openReferralModal(){
      this.referModalVisible = true;
    },
    referCase(){
      axios.post(this.$store.getters.url+'/api/api-add-participant', {
        docket_id : this.returned_cases.id,
        user_id : this.selected_user.id,
        type : 'referee'
      }).then(() => {
          this.referModalVisible = false;
          this.$emit('reload');
      })
    },
    acceptCase(){
      axios.post(this.$store.getters.url+'/api/api-docket-accept-participation', {
        docket_id : this.returned_cases.id,
        user_id : this.user_id,
      }).then(() => {
        Notify.create({
          color: 'green-5',
          textColor: 'white',
          position: 'center',
          icon: 'check',
          message: 'Case accepted',
          timeout: 10000,
        })
        this.$emit('reload');
      })
    },
    rejectCase(){
      axios.post(this.$store.getters.url+'/api/api-docket-reject-participation', {
        docket_id : this.returned_cases.id,
        user_id : this.user_id
      }).then(() => {
        Notify.create({
          color: 'green-5',
          textColor: 'white',
          position: 'center',
          icon: 'check',
          message: 'Case accepted',
          timeout: 10000,
        })
        let router = useRouter();
        router.push({ name: "home"})
      })
    },
    followCase(){
      axios.post(this.$store.getters.url+'/api/cases/case-followers', {
        case_id : this.returned_cases.id,
        user_id : this.selected_user.id
      }).then(() => {
        this.referModalVisible = false;
        this.$emit('reload');
      })
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users',{
        params: {
          user_id: this.$store.getters.user_id
        }
      })
        .then((res) => {
            this.users = res.data;
        })
        .catch(() => {

        }).finally(() => {

    });
    },

  }

}
</script>
