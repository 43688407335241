import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import OpenTasks from "@/views/OpenTasks.vue";
import ExecuteTask from "@/views/ExecuteTask.vue";
import CheckInStaff from "@/views/CheckInStaff.vue";
import ListStock from "@/views/ListStock.vue";
import CountStock from "@/views/CountStock.vue";
import MyReports from "@/views/Case/MyReports.vue";

import store from './stores/appStore';
import ShowCase from "@/views/Case/ShowCase.vue";
import MyCases from "@/views/Case/MyCases.vue";


const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true }
      },
      {
        path: '/url-login/:url',
        name: 'url-login',
        component: Login,
        meta: {requiresAuth: false}
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {requiresAuth: false}
      },
      {
        path: '/open-tasks',
        name: 'open',
        component: OpenTasks,
        meta: { requiresAuth: true }
      },
      {
        path: '/case/:id',
        name: 'case',
        component: ShowCase,
        meta: { requiresAuth: true }
      },
      {
        path: '/open-reports',
        name: 'open-reports',
        component: MyReports,
        meta: { requiresAuth: true }
      },
      {
        path: '/my-reports',
        name: 'reports',
        component: MyCases,
        meta: { requiresAuth: true }
      },
      {
        path: '/clock-staff',
        name: 'clock-staff',
        component: CheckInStaff,
        meta: { requiresAuth: true }
      },
      {
        path: '/execute-task/:id',
        name: 'execute-task',
        component: ExecuteTask,
        meta: { requiresAuth: true }
      },
      {
        path: '/list-stock',
        name: 'list-stock',
        component: ListStock,
        meta: { requiresAuth: true }
      },
      {
        path: '/count-stock/:id',
        name: 'count-stock',
        component: CountStock,
        meta: { requiresAuth: true }
      },

      {
        path: '/logout',
        name: 'logout',
        component: Logout
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Checking if the route requires being logged in
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // Assume there's a boolean in your Vuex state indicating login status
  const token = store.state.token;

  if (requiresAuth && !token) {
    // Redirect to the login page if not logged in and trying to access a restricted route
    next({ name: 'login' });
  } else {
    // Proceed to the route
    next();
  }
});
export default router
