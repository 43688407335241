
import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
  },
  config: {
    // quasar.config.js
    pwa: {
      // workboxPluginMode: 'InjectManifest',
      // workboxOptions: {},
      manifest: {
        name: process.env.VUE_APP_NAME,
        short_name: process.env.VUE_APP_NAME,
        description: process.env.VUE_APP_NAME,
        icons: [
          {
            'src': 'icons/android-chrome-192x192.png',
            'sizes': '192x192',
            'type': 'image/png'
          },
          {
            'src': 'icons/favicon-16x16.png',
            'sizes': '16x16',
            'type': 'image/png'
          },
          {
            'src': 'icons/favicon-32-32.png',
            'sizes': '32x32',
            'type': 'image/png'
          },
          {
            'src': 'icons/android-chrome512x512.png',
            'sizes': '512x512',
            'type': 'image/png'
          },
          {
            'src': 'icons/apple-touch-icon.png',
            'sizes': '512x512',
            'type': 'image/png'
          }
        ],
        loadingScreenBackgroundColor: 'white',
        display: 'standalone',
        orientation: 'portrait',
        background_color: '#ffffff',
        theme_color: '#ffffff'
      }
    }
  }
}