<template>
  <div class="q-card--bordered">

    <Vue3Signature  ref="signature1" :sigOption="state.option" :w="'200px'" :h="'200px'"
                    :disabled="state.disabled" class="example"></Vue3Signature>
    <q-btn @click.prevent="save('image/jpeg')">Confirm</q-btn>
    <q-btn @click.prevent="clear">Clear</q-btn>
  </div>

</template>

<script setup>
import {onMounted, reactive, ref} from 'vue'
import { defineProps } from 'vue';
import { defineEmits } from 'vue';
const emit = defineEmits(['update:extension','update:files']);

const props = defineProps({
  item_id: String,
  modelValue: File,
})

const state = reactive({
  count: 0,
  option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(255,255,255)"
  },
  disabled: false
})

const signature1 = ref(null)

const save = () => {
  let customerSignature = signature1.value.save('image/jpeg')
  localStorage.setItem(props.item_id+'_signature_base64', (customerSignature));
  state.disabled = !state.disabled;
  fetch(customerSignature)
      .then(res => res.blob())
      .then(blob => {
        let blob_image = new File([blob], 'signature.jpeg', {type: 'image/jpeg'})
        emit('update:files', blob_image);
        emit('update:extension', 'jpeg');
      });
  fromDataURL(customerSignature);
}


const clear = () => {
  signature1.value.clear()
}

// eslint-disable-next-line no-unused-vars
const fromDataURL = (url) => {
  signature1.value.fromDataURL(url);
}

onMounted(() => {
  const reader = new FileReader();
  console.log(props.modelValue);
  reader.onload = function(event) {
    // event.target.result contains the data URL representing the file
    const dataURL = event.target.result;
    fromDataURL(dataURL);
  };
  if(props.modelValue instanceof Blob){
    reader.readAsDataURL(props.modelValue);
  }

});

</script>

<style scoped>
.example{
  margin: 0 auto;
}
</style>