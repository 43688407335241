<template>
  <q-page padding>
    <p>Logout</p>
  </q-page>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: 'PageLogout',
  computed: {
    token: function () {
      return this.$store.getters.token;
    },
  },
  methods:{
    logout() {
      this.$store.dispatch('resetStore');
      let router = useRouter();
      router.push({name: 'login'});
    }
  },
  mounted() {
    this.logout();
  }
}
</script>
