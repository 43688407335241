<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->



  <div :key="this.page_status" id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 100%">
      <div>
        <h5>{{this.app_status}}</h5>
        <q-card class="my-card bg-gray text-gray">
          <q-card-section>
            <q-item v-ripple>
              <q-item-section class="text-h5">
                {{this.clock_type}} Officers
              </q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
              <q-item-section avatar>
              </q-item-section>
            </q-item>
          </q-card-section>

        </q-card>
          <q-card class="bg-teal text-white" style="width: 100%">
            <q-card-section class="q-pt-none">
              <q-form
                  method="post" @submit="this.submitChecklists()"
                  class="q-gutter-md">
                <q-card-section>
                  <label>Clock In or Out</label>
                  <q-select
                      filled
                      v-model="this.clock_type"
                      :options="['Select Clock In or Out','Clock In', 'Clock Out']"
                      label="Choose Type"
                  ></q-select>
                </q-card-section>
                <q-card-section>
                  <label>Choose the Officer to {{this.clock_type}}</label>
                  <q-select
                      filled
                      v-model="this.multiple"
                      option-label="full_name"
                      :options="this.options"
                      label="Choose Employee"
                  ></q-select>
                </q-card-section>
              </q-form>
            </q-card-section>
            <q-card-actions align="right" class="bg-white text-teal">
              <q-btn label="Cancel" @click="this.cancelScreen()"  color="secondary"></q-btn>
              <q-btn label="Done" @click="this.submitClockIn()" type="submit" color="primary"></q-btn>
            </q-card-actions>
          </q-card>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRoute } from 'vue-router'
import { Notify } from 'quasar';
import { Geolocation } from '@capacitor/geolocation'
export default {
  name: 'CheckInStaffPage',
  components: {

  },
  computed:{
    token(){
      return this.$store.getters.token;
    },
    url(){
      return this.$store.getters.url;
    },
    current_level_five(){
      return this.$store.getters.current_level_five;
    },
    user_id(){
      return this.$store.getters.user_id;
    },
  },
  setup(){

  },
  data(){
    return {
      clock_type: 'Select Clock In or Out',
      multiple : [],
      options : [],
      clockUsers : [],
      longitude : null,
      latitude : null
    }
  },
  mounted(){
     this.setupParameters();
     this.getUsers();
     this.getLocation();
  },
  methods : {
    getUsers(){
          axios.get(this.url+'/api/api-users',{
            headers :{
              Authorization : 'Bearer '+this.token
            },
            params:{
               user_id : this.user_id
            }
          })
          .then((res) => {
            this.users = res.data;
            this.options = this.users;
            Notify.create({
              message: 'Users loaded',
              color: 'green',
              position: 'top',
              timeout: 2000
            });
          })
          .catch(() => {

          }).finally(() => {
      });
    },
    getLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      }
    },
    cancelScreen(){
      this.$router.push({ name: 'home'});
    },
    submitClockIn(){
      let config = {
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      }
      if(this.clock_type !== 'Clock In' && this.clock_type !== 'Clock Out'){
        Notify.create({
          message: 'Please select Clock In or Clock Out',
          color: 'red',
          position: 'top',
          timeout: 2000
        });
        return;
      }
      if(this.clock_type==='Clock In') {
        axios.post(this.url + '/api/api-clock-users', {
          user_id: this.user_id,
          company_level_five_id: this.current_level_five.id,
          clock_in_time: new Date(),
          clock_user: this.multiple.id,
        }, config)
            .then((res) => {
              console.log(res);
              Notify.create({
                message: res.data.message,
                color: 'green',
                position: 'top',
                timeout: 2000
              });
              this.multiple = null;
            }).catch((error) => {
          console.log(error);
          Notify.create({
            message: error.response.data.message,
            color: 'red',
            position: 'top',
            timeout: 2000
          });
        });
      }
      if(this.clock_type==='Clock Out'){
          axios.post(this.url+'/api/api-clock-users', {
            user_id : this.user_id,
            company_level_five_id: this.current_level_five.id,
            clock_out_time : new Date(),
            clock_user : this.multiple.id,
          },config)
              .then((res) => {
                console.log(res);
                Notify.create({
                  message: res.data.message,
                  color: 'green',
                  position: 'top',
                  timeout: 2000
                });
                this.multiple = null;
              }).catch((error) => {
                    console.log(error);
                    Notify.create({
                      message: error.response.data.message,
                      color: 'red',
                      position: 'top',
                      timeout: 2000
                    });
                  });
          }
    },
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
    },

    forceRefresh(){
      this.page_status += 1;
      console.log("Force refresh called KEY:"+this.page_status);
      this.$forceUpdate();  // Notice we have to use a $ here
    },

  }
}
</script>
